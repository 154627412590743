<template>
  <el-dialog
    id="EditSignatoryDialog"
    :title="title"
    :visible.sync="dialogShow"
    center
    modal
    top="5vh"
    :close-on-click-modal="false"
    width="496px"
    close-on-press-escape
    class="my-dialog abow_dialog"
    @opened="onShow"
    destroy-on-close
    append-to-body
  >
    <el-form ref="EditSignatoryForm" :model="model" :rules="rules" @submit.native.prevent="onSubmit" v-loading="loading">
      <el-row v-if="loading" justify="center" align="middle" type="flex" style="height:430px">
        <el-empty :image-size="100" :image="require('../../../assets/icons/no_data.svg')"></el-empty>
      </el-row>
      <el-row v-else>
        <el-form-item prop="name" :label="$t('Назва')">
          <el-input v-model="model.name" ref="nameInput" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item prop="fullName" :label="$t('Повна назва')">
          <el-input v-model="model.fullName" ref="fullNameInput" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item prop="address" :label="$t('Юридична адреса')">
          <el-input v-model="model.address" ref="addressInput" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item prop="bankName" :label="$t('Банк, назва')">
          <el-input v-model="model.bankName" ref="bankNameInput" autocomplete="off"></el-input>
        </el-form-item>
        <el-row justify="space-between" type="flex">
          <el-col :span="11">
            <el-form-item prop="bankMFO" :label="$t('Банк, МФО')">
              <el-input v-model="model.bankMFO" ref="bankMFO" autocomplete="off" v-mask="'######'" masked="true" clearable placeholder="000000"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item prop="bankEDRPOU" :label="$t('Банк, ЄДРПОУ')">
              <el-input
                v-model="model.bankEDRPOU"
                ref="bankEDRPOU"
                autocomplete="off"
                v-mask="'######'"
                masked="true"
                clearable
                placeholder="00000000"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item prop="IBAN" :label="$t('IBAN')">
          <el-input
            v-model="model.IBAN"
            ref="IBANInput"
            autocomplete="off"
            v-mask="IBANMask"
            masked="true"
            clearable
            placeholder="000000000000000000000000000"
          ></el-input>
        </el-form-item>
        <el-form-item prop="IPN" :label="$t('ІПН')">
          <el-input v-model="model.IPN" ref="IPNInput" autocomplete="off" v-mask="'##########'" masked="true" clearable placeholder="00000000000"></el-input>
        </el-form-item>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button native-type="submit" type="success" @click="onCommit" class="apply-button">{{ $t("Застосувати") }}</el-button>
      <el-button type="text" @click="dialogShow = false" class="cancel-button">{{ $t("Відмінити") }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import { bus } from "@/main";
  import { _ } from "vue-underscore";

  export default {
    name: "EditSignatory",
    props: ["busEvent"],
    data() {
      var that = this;
      var defaultValidateRule = { required: true, message: $tt("Це поле є необхідним") };
      return {
        IBANMask: [
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
        ],
        loading: false,
        dialogShow: false,
        title: "Створення нового підписанта",
        operation: null,
        model: {},
        initModel: {},
        rules: {
          name: [defaultValidateRule],
        },
      };
    },

    methods: {
      onCommit() {
        this.$refs.EditSignatoryForm.validate((valid) => {
          if (valid) {
            this.$emit("commit", { operation: this.operation, model: this.model });
          } else {
            this.$message.error($tt("Перевірте введені дані!"));
          }
          return false;
        });
      },
      onShow() {
        this.$refs.EditSignatoryForm.resetFields();
        this.model = _.extend({}, this.initModel);
        this.$nextTick(() => {
          this.loading = false;
          this.$nextTick(() => {
            this.$refs.nameInput.focus();
          });
        });
      },
    },
    created() {
      bus.$on(this.busEvent + ".show", (data) => {
        //this.loading = true;
        this.title = (data && data.title) || this.title;
        this.operation = (data && data.operation) || "create";
        this.initModel = _.extend({}, data ? data.initModel : {});
        this.dialogShow = true;
      });
      bus.$on(this.busEvent + ".hide", () => {
        this.dialogShow = false;
      });
    },
    computed: {},
  };
</script>
<style lang="scss">
  #EditSignatoryDialog {
    .el-dialog {
      max-height: 650px !important;
    }
  }
</style>
